import "./App.css";
import Header from "./components/layouts/Header";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Footer from "./components/layouts/Footer";
import Home from "./components/Home";
import Dashboard from "./components/admin/Dashboard";
import UserList from "./components/admin/UserList";
import Login from "./components/user/Login";
import Registration from "./components/user/Registration";
import ResetPassword from "./components/user/ResetPassword";
import ForgotPassword from "./components/user/ForgotPassword";
import Order from "./components/driver/Order";
import ProtectedRoute from "./components/route/ProtectedRoute";
import Profile from "./components/user/Profile";
import UpdatePassword from "./components/user/UpdatePassword";
import UpdateProfile from "./components/user/UpdateProfile";
import UpdateUser from "./components/admin/UpdateUser";
import DriversRegister from "./components/admin/DriversRegister";
import DriverList from "./components/admin/DriverList";
import UpdateDriver from "./components/admin/UpdateDriver";
import BookingList from "./components/admin/BookingList";
import BookingSuccess from "./components/booking/BookingSuccess";
import UserBookings from "./components/booking/UserBookings";
import BookingDetails from "./components/booking/BookingDetails";
import store from "./store";
import { loadUser } from "./actions/userActions";
import { useEffect, useState } from "react";
import Map from "./components/booking/Map";
import { ChakraProvider, theme } from "@chakra-ui/react";
import BookingForm from "./components/booking/BookingForm";
import ConfirmOrder from "./components/booking/ConfirmBooking";
import NewBooking from "./components/driver/NewBooking";
import AcceptedBooking from "./components/driver/AcceptedBooking";
import CompletedBooking from "./components/driver/CompletedBooking";
import CustomRegister from "./components/admin/CustomRegister";
import CustomList from "./components/admin/CustomList";
import CompletedDetails from "./components/driver/CompletedDetails";
import Reviews from "./components/driver/Reviews";
import ReviewsDriver from "./components/admin/ReviewsDriver";
import SingleDriver from "./components/admin/SingleDriver";
import SingleUser from "./components/admin/SingleUser";
import SingleBooking from "./components/admin/SingleBooking";
import UpdateBooking from "./components/admin/UpdateBooking";
import CardPackage from "./components/package/CardPackage";
import ConfirmPackage from "./components/package/ConfirmPackage";
import FormPackage from "./components/package/FormPackage";
import PackageRegister from "./components/admin/PackageRegister";
import PackageList from "./components/admin/PackageList";
import ContactList from "./components/admin/ContactList";
import VehicleDetails from "./components/driver/VehicleDetails";
import CancelDriver from "./components/admin/CancelDriver";
import Loader from "./components/layouts/Loader";
import DriverPayment from "./components/admin/DriverPayment";
import AddShare from "./components/driver/AddShare";
import ShareUser from "./components/layouts/ShareUser";
import ShareCard from "./components/layouts/ShareCard";
import ViewShare from "./components/driver/ViewShare";
import ShareList from "./components/admin/ShareList";
import UserShareList from "./components/admin/UserShareList";
import ShareRegister from "./components/admin/ShareRegister";
import Policy from "./components/layouts/Policy";
import BookingFail from "./components/booking/BookingFail";
import Terms from "./components/layouts/Terms";
import UpdateCustom from "./components/admin/UpdateCustom";
import UpdatePackage from "./components/admin/UpdatePackage";
import PageNotFound from "./components/layouts/PageNotFound";
import Refund from "./components/layouts/Refund";
import UpdateShareVehicle from "./components/admin/updateShareVehicle";
import TaxiApp from "./components/layouts/TaxiApp";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 3300);
    store.dispatch(loadUser);
  }, []);
  if (loading) {
    return <Loader />;
  }

  return (
    <Router>
      <div className="App">
        <HelmetProvider>
          <ToastContainer theme="dark" autoClose={2000} />

          <ChakraProvider theme={theme}>
            <Header />
            <div className="allcontent">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Registration />} />
                <Route path="/password/forgot" element={<ForgotPassword />} />
                <Route
                  path="/password/reset/:token"
                  element={<ResetPassword />}
                />
                <Route path="/sharevehicle" element={<ShareCard />} />
                <Route path="/bookshare/:id" element={<ShareUser />} />
                <Route path="/policy" element={<Policy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/refund" element={<Refund />} />
                <Route path="/taxiapp" element={<TaxiApp />} />
                <Route
                  path="/map"
                  element={
                    <ProtectedRoute>
                      <Map />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/booking"
                  element={
                    <ProtectedRoute>
                      <BookingForm />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/confirm"
                  element={
                    <ProtectedRoute>
                      <ConfirmOrder />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/myprofile"
                  element={
                    <ProtectedRoute>
                      <Profile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/myprofile/update"
                  element={
                    <ProtectedRoute>
                      <UpdateProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/myprofile/update/password"
                  element={
                    <ProtectedRoute>
                      <UpdatePassword />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/booking/success"
                  element={
                    <ProtectedRoute>
                      <BookingSuccess />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/booking/fail"
                  element={
                    <ProtectedRoute>
                      <BookingFail />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/mybookings"
                  element={
                    <ProtectedRoute>
                      <UserBookings />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/booking/:id"
                  element={
                    <ProtectedRoute>
                      <BookingDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/package"
                  element={
                    <ProtectedRoute>
                      <CardPackage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/packageform"
                  element={
                    <ProtectedRoute>
                      <FormPackage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/packageconfirm"
                  element={
                    <ProtectedRoute>
                      <ConfirmPackage />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/admin/dashboard"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/users"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UserList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/user/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UpdateUser />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/singleuser/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <SingleUser />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/drivers/create"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <DriversRegister />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/drivers"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <DriverList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/driver/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UpdateDriver />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/driver/payment/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <DriverPayment />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/singledriver/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <SingleDriver />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/bookings"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <BookingList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/bookings/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UpdateBooking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/singlebooking/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <SingleBooking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/custom/new"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <CustomRegister />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/custom"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <CustomList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/custom/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UpdateCustom />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/reviews/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <ReviewsDriver />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/cancel/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <CancelDriver />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/package/new"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <PackageRegister />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/package"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <PackageList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/package/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UpdatePackage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/contacts"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <ContactList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/share"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <ShareList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/viewshare/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UserShareList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/updateshare/:id"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <UpdateShareVehicle />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/admin/share/new"
                  element={
                    <ProtectedRoute isAdmin={true}>
                      <ShareRegister />
                    </ProtectedRoute>
                  }
                />
                <Route path="notfound" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to="/notfound" replace />} />

                <Route
                  path="/driver/order"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <Order />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/newbooking"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <NewBooking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/acceptbooking"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <AcceptedBooking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/completedbooking"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <CompletedBooking />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/completedDetail/:id"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <CompletedDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/reviews"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <Reviews />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/mydetails"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <VehicleDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/addshare"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <AddShare />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/driver/viewshare/:id"
                  element={
                    <ProtectedRoute isDriver={true}>
                      <ViewShare />
                    </ProtectedRoute>
                  }
                />

                <Route path="notfound" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to="/notfound" replace />} />
              </Routes>
            </div>

            <Footer />
          </ChakraProvider>
        </HelmetProvider>
      </div>
    </Router>
  );
}

export default App;
