import { Fragment } from "react";
import MetaData from "./MetaData";
import { Link } from "react-router-dom";

export default function TaxiApp() {
  return (
    
    <Fragment>
      <MetaData title={"Bro3 Taxi App"} />

      <div className="taxiapp" id="app">
        <div className="one">
          <div className="banner-taxiapp"></div>
        </div>
        <div className="two">
          <div className="user">
            <h3>BRO3 USER APP</h3>
            <div className="playstore">
              <Link to="https://play.google.com/store/apps/details?id=com.bro3.bro3usersapp">
                <img className="playstoreimg"
                  src="/images/playstore.png"
                  style={{ height: 100, width: 250 }}
                  alt="Playstore"
                />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.bro3.bro3usersapp">
                <img className="qrimg"
                  src="/images/qruser.jpg"
                  style={{ height: 125, width: 125 }}
                  alt="Playstore"
                />
              </Link>
            </div>
          </div>
          <div className="driver">
            <h3>BRO3 DRIVER APP</h3>
            <div className="playstore">
              <Link to="https://play.google.com/store/apps/details?id=com.bro3.bro3driversapp">
                <img className="playstoreimg"
                  src="/images/playstore.png"
                  style={{ height: 100, width: 250 }}
                  alt="Playstore"
                />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.bro3.bro3driversapp">
                <img className="qrimg"
                  src="/images/qrdriver.jpg"
                  style={{ height: 125, width: 125 }}
                  alt="Playstore"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
